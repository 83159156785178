import React, { useEffect, useState } from "react";
import Translations from "../Translations";
import TextContainerPlaceholder from "../Placeholders/TextContainerPlaceholder";

export default function VerseTranslation(props){
    const [translation ,setTranslation] = useState([])
    const [activeTranslation, setActiveTranslation] = useState(131)

    useEffect(() => {
        getTranslations()
    }, [activeTranslation])

    /**
     * get translation
     */
    function getTranslations(){
        fetch(`https://api.quran.com/api/v4/quran/translations/${activeTranslation}?verse_key=${props.verseKey}`)
        .then(response => (response.json()))
        .then(json => {
            setTranslation(json.translations)
        })
    }

    /**
     * handle translation change
    */
    function handleTranslationChange(translationId){
        setActiveTranslation(translationId)
        getTranslations()
    }

    /**
     * render translations
     */
    function renderTranslation(){
        return translation ? (
            <p dir="ltr" className="text-left text-lg my-4 p-3" dangerouslySetInnerHTML={{__html:translation.length > 0 && translation[0].text}} />
        ) : (
            <TextContainerPlaceholder />
        )
    }

    return (
        <>
          <Translations activeTranslation={activeTranslation} handleTranslationChange={handleTranslationChange} />
          {renderTranslation()}
        </>
    )
}