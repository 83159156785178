import React, { useState } from "react";
import BookmarkedVerse from "./BookmarkedVerse";
import { useTranslation } from "react-i18next";

export default function BookmarksContainer(props){
    const [bookmarks] = useState(loadBookmarksFromLocalStorage())
    const [t] = useTranslation()

    /**
     * load bookmarkes from localstorage
    */
    function loadBookmarksFromLocalStorage(){
        let bookmarked_verses = localStorage.getItem('bookmarked_verses')
        if(bookmarked_verses){
            return JSON.parse(bookmarked_verses)
        }
    }

    /**
     * extract verse number from verse key
     */
    function getVerseNumber(verseKey){
        return verseKey.split(':')[1]
    }

    /**
     * extract verse's chapter name based on its key
     */
    function getVerseChapterName(verseKey){
        return props.chapters ? props.chapters[
            verseKey.split(':')[0] - 1
        ] : ''
    }

    /**
     * extract chapter id from verse key
     */
    function getChapterId(verseKey){
        return verseKey.split(':')[0]
    }
    
    /**
     * render bookmarks
    */
    function renderBookmarkedVerses(){
        return bookmarks && bookmarks.length > 0 ? bookmarks?.map(bv => (
            <BookmarkedVerse key={bv} 
            verseNumber={getVerseNumber(bv)} 
            chapter_name={getVerseChapterName(bv)} 
            chapter_id={getChapterId(bv)} />
        )) : (
            <div>
                {t('There is no bookmarked verses yet!')}
            </div>
        )
    }

    return renderBookmarkedVerses()
}