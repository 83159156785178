import React, { useEffect, useState } from "react";

export default function Translations(props){
    const [translations ,setTranslations] = useState([])

    useEffect(() => {
        getTranslationsList()
    },[])

    /**
     * load translations from API
    */
    function loadTranslationsFromAPI(){
        fetch('https://api.quran.com/api/v4/resources/translations?language=ar')
        .then(response => (response.json()))
        .then(json => {
            setTranslations(json.translations)
            localStorage.setItem('translations_list', JSON.stringify(json.translations))
        })
    }

    /**
     * load translations from localStorage
    */
    function loadTranslationsFromLocalStorage(){
        let translations = localStorage.getItem('translations_list')
        translations = JSON.parse(translations)
        setTranslations(translations)
    }

    /**
     * load translations list
     */
    function getTranslationsList(){
        localStorage.getItem('translations_list')
        ? loadTranslationsFromLocalStorage()
        : loadTranslationsFromAPI()
    }

    return (
        <>
          <select value={props.recitatorId} className="border dark:bg-neutral-700 dark:border-0 p-2 rounded-md w-full" onChange={(e) => props.handleTranslationChange(e.target.value)}>
            {translations.map(translation => (
                <option key={translation.id} value={translation.id}>
                    {translation.name} ({translation.language_name})
                </option>
            ))}
          </select>
        </>
    )
}