import React from "react";
import LanguageSwitcher from "../../Components/LanguageSwitcher";
import { Link } from "react-router-dom";
import ModeSwitcher from "../../Components/ModeSwitcher";

export default function Header(){
    return (
        <header className="text-gray-600 body-font bg-neutral-100 dark:bg-neutral-800">
          <div
            className="container mx-auto flex p-2 md:p-5 items-center"
          >
            <Link to={`/`} className="flex title-font font-medium items-center text-gray-900 py-2">
              <img src={require('../../logo.png')} className="w-14 md:w-24" />
            </Link>
            <div className="flex-1"></div>
            <nav className="flex gap-4 flex-wrap items-center text-base justify-center">
              <LanguageSwitcher />
              <ModeSwitcher />
            </nav>
          </div>
        </header>
    )
}