import React from "react";

export default function Banner(){
    return (
      <div className="h-40 md:h-80 flex text-center relative" style={{backgroundSize: 'cover', backgroundImage: `url(${require('../quraan-bg-2.jpg')})`}}>
        <p className="m-auto verse text-xl md:text-6xl font-bold text-white z-20" dir="rtl">
        ﴿
        وَنَزَّلْنَا عَلَيْكَ الْكِتَابَ تِبْيَانًا لِّكُلِّ شَيْءٍ
        ﴾
        </p>
        <div className="absolute h-full w-full top-0 right-0 bg-emerald-600 opacity-30"></div>
      </div>
    )
}