import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageSwitcher(){
    const {i18n} = useTranslation()
    const [activeLocale, setActiveLocale] = useState(i18n.language)

    /**
     * switch i18next language
     */
    function switchLanguage(locale){
        i18n.changeLanguage(locale)
        localStorage.setItem("locale" ,locale)
        setActiveLocale(locale)
    }

    /**
     * render switch locale button
     */
    function renderButton(){
        return (activeLocale === 'en')
        ? <a onClick={() => switchLanguage('ar')} className="relative cursor-pointer mr-5 dark:text-neutral-50 hover:text-emerald-600 ">
            <svg
              className="h-5 w-5 md:h-8 md:w-8"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <path stroke="none" d="M0 0h24v24H0z" />{" "}
              <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />{" "}
              <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
            </svg>
            <span className="absolute -bottom-5 -left-5">(ar)</span>
        </a>
        : <a onClick={() => switchLanguage('en')} className="relative cursor-pointer mr-5 dark:text-neutral-50 hover:text-emerald-600 ">
            <svg
              className="h-5 w-5 md:h-8 md:w-8"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <path stroke="none" d="M0 0h24v24H0z" />{" "}
              <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />{" "}
              <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
            </svg>
            <span className="absolute -bottom-5 -right-5">(en)</span>
        </a>              
    }

    return renderButton();    
}