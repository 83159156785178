import React, { useState } from "react";

export default function ModeSwitcher(){
    const [activeMode, setActiveMode] = useState(() => {
        return localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light'
    })

    /**
     * switch between dark and light theme
     */
    function switchMode(mode){
        document.documentElement.classList.remove( mode === 'dark' ? 'light' : 'dark' )
        document.documentElement.classList.add( mode === 'dark' ? 'dark' : 'light' )
        localStorage.setItem("mode" ,mode)
        setActiveMode(mode)
    }

    /**
     * render switch mode button
     */
    function renderButton(){
        return (activeMode === 'light') ? (
            <button className="mx-2" onClick={() => switchMode('dark')}>
                <svg
                  className="h-5 w-5 md:h-6 md:w-6 text-gray-500 hover:text-emerald-600"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <path d="M16.2 4a9.03 9.03 0 1 0 3.9 12a6.5 6.5 0 1 1 -3.9 -12" />
                </svg>
            </button>
        ) : 
        (
            <button className="mx-2" onClick={() => switchMode('light')}>
                <svg
                  className="h-5 w-5 md:h-6 md:w-6 text-yellow-600 hover:text-emerald-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <circle cx={12} cy={12} r={5} /> <line x1={12} y1={1} x2={12} y2={3} />{" "}
                  <line x1={12} y1={21} x2={12} y2={23} />{" "}
                  <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />{" "}
                  <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />{" "}
                  <line x1={1} y1={12} x2={3} y2={12} />{" "}
                  <line x1={21} y1={12} x2={23} y2={12} />{" "}
                  <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />{" "}
                  <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                </svg>
            </button>
        )
        return (activeMode === 'dark')
        ? <a onClick={() => switchMode('light')} className="cursor-pointer mr-5 hover:text-gray-900">light</a>
        : <a onClick={() => switchMode('dark')} className="cursor-pointer mr-5 hover:text-gray-900">dark</a>              
    }

    return renderButton();    
}