import React ,{useEffect, useState} from "react";
import Tafsirs from "../Tafsirs";
import TextContainerPlaceholder from "../Placeholders/TextContainerPlaceholder";

export default function VerseTafsir(props){
    const [tafsir, setTafsir] = useState('')
    const [activeTafsir, setActiveTafsir] = useState(90)
    const [fontSize, setFontSize] = useState(1)
    const [fontSizeClass, setFontSizeClass] = useState('text-lg')

    useEffect(() => {
        getTafsir()
    },[activeTafsir])

    /**
     * load tafsir for specific verse
     */
    function getTafsir(){
        fetch(`https://api.quran.com/api/v4/verses/by_key/${props.verseKey}?words=false&tafsirs=${activeTafsir}`)
        .then(response => (response.json()))
        .then(json => setTafsir(json.verse.tafsirs))
    }

    /**
     * re load a new tafsir
     */
    function handleTafsirChange(tafsirId){
        setActiveTafsir(tafsirId)
        getTafsir(tafsirId)
    }

    /**
     * hanlde font size change
    */
    function changeFontSize(amount){
        if(amount >= 1 && amount <= 3){
            if(amount == 1){
                setFontSizeClass('text-lg')
                setFontSize(amount)
            }else{
                setFontSizeClass('text-'+amount+'xl')
                setFontSize(amount)
            }
        }
    }

    /**
     * render tafsir
    */
    function renderTafsir(){
        return tafsir ? (
            <p className={`my-8 ${fontSizeClass}`} dangerouslySetInnerHTML={{__html:tafsir && tafsir[0]?.text}} />
        ) : (
            <TextContainerPlaceholder />
        )
    }

    return (
        <>
            <Tafsirs activeTafsir={activeTafsir} handleTafsirChange={handleTafsirChange} />

            <div className="flex-col flex gap-2 fixed left-0">
               <button onClick={() => changeFontSize(fontSize + 1)} className="px-4 text-3xl font-bold bg-gray-200 rounded dark:bg-neutral-700 hover:opacity-50">+</button>
               <button onClick={() => changeFontSize(fontSize - 1)} className="px-4 text-3xl font-bold bg-gray-200 rounded dark:bg-neutral-700 hover:opacity-50">-</button>
            </div>

            {renderTafsir()}
        </>
    )
}