import React from 'react';
import ReactDOM from 'react-dom/client';
import './Style/index.css';
import { RouterProvider} from "react-router-dom";
import router from './Router';
import './Plugins/I18n/i18next.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <RouterProvider router={router} />
);