import React, { useEffect, useRef, useState } from "react";
import { VerseSymbol } from "./VerseSymbol";
import VerseModal from "./VerseModal";

export default function Verse(props){
    const [isVisible ,setIsVisible] = useState(false)
    const [isBookmarked, setIsBookmared] = useState(isAlreadyBookmarked())
    const verseRef = useRef()
    const [isTargetedViaUrl, setIsTargetedViaUrl] = useState(false)

    useEffect(() => {
        scrollToVerseIfWanted()
    },[])
    /**
     * return a valid css class
     */
    function renderFontSize(){
        return `text-${props.fontSize}xl`;
    }

    /**
     * check if a verse is bookmarked
    */
    function isAlreadyBookmarked(){
        let bookmarked_verses = localStorage.getItem('bookmarked_verses')
        
        if(bookmarked_verses){
            bookmarked_verses = JSON.parse(bookmarked_verses)
            return bookmarked_verses.includes(props.verse.verse_key)
        }
        return false
    }

    /**
     * check whether ?verse_number is present as query param in url
     * and if yes scroll to the needed verse
    */
    function scrollToVerseIfWanted(){
        let url = new URL(window.location.href);

        if (url.searchParams.has('verse_number')) {
            let target = url.searchParams.get('verse_number')
            if(Number(target) === props.verse.verse_number){
                verseRef.current.scrollIntoView()
                setIsTargetedViaUrl(true)
            }
        }
    }
    
    return(
        <div ref={verseRef} className="inline pt-8">
           <div onClick={() => setIsVisible(true)} 
           
           className={`${renderFontSize()} ${isTargetedViaUrl ? 'bg-yellow-400 dark:bg-opacity-40' : ''} verse inline leading-loose cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700`}> 
               { props.verse.text_uthmani }
           </div>
           <span> <VerseSymbol number={props.verse.verse_number} />  </span>
           {isVisible ? 
           <VerseModal 
           isBookmarked={isBookmarked}
           verseText={props.verse.text_uthmani} 
           verseKey={props.verse.verse_key} 
           hideModal={() => setIsVisible(false)} /> 
           : null}
        </div>
    )
}