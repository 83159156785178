import React, { useState ,useEffect} from "react";
import { useParams } from "react-router-dom";
import Verse from "../Components/Verse/Verse";
import RecitationAudio from "../Components/RecitationAudio";
import Bassmalah from "../Components/Bassmalah";
import TextContainerPlaceholder from "../Components/Placeholders/TextContainerPlaceholder";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function SurahPage(){
    const {chapterId} = useParams()
    const [chapterVerses ,setChapterVerses] = useState([])
    const [chapter ,setChapter] = useState([])
    const [recitation, setRicitation] = useState([])
    const [recitator, setRecitator] = useState(1)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [fontSize, setFontSize] = useState(3)
    const [isLoading, setIsLoading] = useState(true)
    const {t ,i18n} = useTranslation()
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        loadChapterVerses()
        loadChapterInfo()
        loadRecitation()
    },[recitator])

    /**
     * load chapter verses
     */
    function loadChapterVerses(){
        fetch(`https://api.quran.com/api/v4/verses/by_chapter/${chapterId}?fields=text_uthmani&per_page=287`)
        .then(response => {return response.json()})
        .then(json => {
            setChapterVerses(json.verses)
            setIsLoading(false)
        })
    }

    /**
     * load chapter info
     */
    function loadChapterInfo(){
        fetch(`https://api.quran.com/api/v4/chapters/${chapterId}`)
        .then(response => {return response.json()})
        .then(json => {
            setChapter(json.chapter)
        })
    }

    /**
     * load recitation
     */
    function loadRecitation(){
        fetch(`https://api.quran.com/api/v4/chapter_recitations/${recitator}/${chapterId}`)
        .then(response => {return response.json()})
        .then(json => {
            setRicitation(json.audio_file)
        })
        .then(() => setForceUpdate((prev) => !prev))
    }

    /**
     * handle recitation change
    */
    function handleRecitationChange(recitatorId){
        setRecitator(recitatorId)
        loadRecitation()
    }

    /**
     * hanlde font size change
    */
    function changeFontSize(amount){
        if(amount > 1 && amount <= 6){
            setFontSize(amount)
        }
    }

    /**
     * render surah or placeholder
    */
    function renderSurah(){
        if(isLoading){
            return <TextContainerPlaceholder />
        }else{
            return (
                <>
                  {chapter.bismillah_pre && <Bassmalah /> }
                  {chapterVerses?.map(verse => ( <Verse fontSize={fontSize} key={verse.id} verse={verse} />))}
                </>
            )
        }
    }

    /**
     * render resize buttons
    */
    function renderResizeButtons(){
        return (
            <div className="flex-col flex gap-2 fixed left-0">
               <button onClick={() => changeFontSize(fontSize + 1)} className="px-2 md:px-4 text-3xl font-bold bg-gray-200 rounded dark:bg-neutral-700 hover:opacity-50">+</button>
               <button onClick={() => changeFontSize(fontSize - 1)} className="px-2 md:px-4 text-3xl font-bold bg-gray-200 rounded dark:bg-neutral-700 hover:opacity-50">-</button>
            </div>
        )
    }

    /**
     * render surah header
    */
    function renderSurahHeader(){
        return (
            <div className="text-center flex-col py-4">
              <h2 className="text-2xl md:text-5xl font-bold flex-1 verse" dir="rtl">
                ﴿
                {(i18n.language === 'ar' ? ('سورة ' + chapter.name_arabic) : chapter.name_complex)}
                ﴾
              </h2>
          </div>
        )
    }

    /**
     * render page title
    */
    function renderPageTitle(){
        return `${t('App name')} | ${(i18n.language === 'ar' ? ('سورة ' + chapter.name_arabic) : chapter.name_complex)}`
    }

    /**
     * render audio recitation 
    */
    function renderAudioRecitation(){
        return isVisible ? (
            <div className="fixed w-screen bg-neutral-100 dark:bg-neutral-800 pt-6 bottom-0 right-0 left-0 z-20">
              <RecitationAudio 
              key={forceUpdate}
              recitatorId={recitator}
              changeRecitation={(e) => handleRecitationChange(e.target.value)} 
              url={recitation.audio_url} />
    
              <button onClick={() => setIsVisible(false)} className={`absolute top-1 ${i18n.language === 'ar' ? 'left-2 md:left-4' : 'right-2'}`}>
                <svg
                  className="h-8 w-8 text-red-500"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <line x1={18} y1={6} x2={6} y2={18} /> <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </button>
            </div> 
        ) : null;
    }

    return (
        <div className="container p-5 mx-auto border-x dark:border-neutral-700 py-8">
            <Helmet>
                <title>{renderPageTitle()}</title>
            </Helmet>

            {renderSurahHeader()}
            {renderAudioRecitation()}
  
            <div className="pt-8 mt-5 border-t dark:border-neutral-700 text-right">
                {renderResizeButtons()}
                {renderSurah()}
            </div>
        </div>
    )
}