import React from "react";

export default function AudioPlaceholder(){
    return (
        <div className="animate-pulse flex gap-4 w-full">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>

          <div className="rounded-full bg-slate-200 h-20 w-20"></div>
        </div>
    )
}