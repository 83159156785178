import React, { useEffect, useState } from "react";

export default function Tafsirs(props){
    const [tafsirs ,setTafsirs] = useState([])

    useEffect(() => {
        getTafsirsList()
    },[])

    /**
     * load tafsirs from local storage
     */
    function loadTafsirsFromLocalStorage(){
        let tafsirs = localStorage.getItem('tafsirs_list')
        tafsirs = JSON.parse(tafsirs)
        setTafsirs(tafsirs)
    }

    /**
     * load tafsirs from API
    */
    function loadTafsirsFromAPI(){
        fetch('https://api.quran.com/api/v4/resources/tafsirs?language=ar')
        .then(response => (response.json()))
        .then(json => {
            setTafsirs(json.tafsirs)
            localStorage.setItem('tafsirs_list', JSON.stringify(json.tafsirs))
        })
    }

    /**
     * load tafsirs list
     */
    function getTafsirsList(){
        localStorage.getItem('tafsirs_list')
        ? loadTafsirsFromLocalStorage()
        : loadTafsirsFromAPI()
    }

    return (
        <div className="flex gap-2 overflow-x-scroll items-center pb-4">
            {tafsirs.map(tafsir => (
                <div 
                key={tafsir.id}
                onClick={() => props.handleTafsirChange(tafsir.id)} 
                className={`bg-neutral-700 dark:bg-neutral-700 p-2 rounded-lg cursor-pointer flex-none ${(tafsir.id === props.activeTafsir) ? 'bg-opacity-30 dark:bg-neutral-900' : 'bg-opacity-5'}`}>
                    <b>{tafsir.translated_name.name}</b>
                    ({tafsir.language_name})
                </div>
            ))}
        </div>
    )
}