import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ChapterItem(props){
  const {t, i18n} = useTranslation()
  
  return (
    <Link to={`/${props.chapter.id}`} className="p-8 bg-white border shadow-lg dark:border-neutral-800 dark:bg-neutral-800 rounded cursor-pointer hover:opacity-40">
      <div className="h-full flex items-start">
        <div className="flex-grow ">
          <h2 className="text-sm title-font font-medium text-emerald-500 mb-1">
            {props.chapter.revelation_place === 'madinah' ? t('Madaniyah') : t('Makkiyah') }
          </h2>
          <h1 className="title-font text-3xl font-medium text-neutral-900 dark:text-neutral-50 mb-3 verse">
            {i18n.language === 'ar' ? ('سورة ' + props.chapter.name_arabic) : props.chapter.name_complex}
          </h1>
        </div>

        <div className="w-12 mr-auto flex-shrink-0 flex flex-col text-center leading-none">
          <span className="text-neutral-500 dark:text-neutral-50 pb-2 mb-2 border-b-2 border-neutral-200">
            {t('Verses')}
          </span>
          <span className="font-medium text-2xl font-bold text-emerald-600 dark:text-neutral-50 title-font leading-none">
            {props.chapter.verses_count}
          </span>
        </div>
      </div>
    </Link>
  )
}