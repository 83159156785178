import React ,{useEffect, useState} from "react";
import RecitationAudio from "../RecitationAudio";
import VerseTafsir from "./VerseTafsir";
import VerseTranslation from "./VerseTranslation";
import { useTranslation } from "react-i18next";

export default function VerseModal(props){
    const [recitations ,setRecitations] = useState([])
    const [recitator, setRecitator] = useState(1)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [activeTab, setActiveTab] = useState('recitation')
    const [isBookmarked, setIsBookmared] = useState(props.isBookmarked)
    const {t ,i18n} = useTranslation()

    
    useEffect(() => {
        getRecitation()
    },[recitator])


    /**
     * get recitation audio file
     */
    function getRecitation(){
        fetch(`https://api.quran.com/api/v4/recitations/${recitator}/by_ayah/${props.verseKey}`)
        .then(response => {return response.json()})
        .then(json => {
            setRecitations(json.audio_files)
            setForceUpdate((prev) => !prev)
        })
    }

    /**
     * change recitator
     */
    function handleRecitationChange(recitatorId){
        setRecitator(recitatorId)
        getRecitation()
    }

    /**
     * render tabs
    */
    function renderTabs(){
        if(activeTab === 'recitation'){
            return recitations[0]?.url ? (
                <RecitationAudio
                className="mt-8"
                key={forceUpdate}
                recitatorId={recitator}
                changeRecitation={(e) => handleRecitationChange(e.target.value)} 
                url={`https://verses.quran.com/${recitations[0]?.url}`} />
            ) : null
        }else if(activeTab === 'tafsirs'){
            return (
                <VerseTafsir verseKey={props.verseKey} />
            )
        }else if(activeTab === 'translations'){
            return (
                <VerseTranslation verseKey={props.verseKey}/>
            )
        }
    }

    /**
     * bookmark verse
    */
    function bookmarkVerse(){
        let bookmarked_verses = localStorage.getItem('bookmarked_verses')
        
        if(bookmarked_verses){
            bookmarked_verses = JSON.parse(bookmarked_verses)
            if(!bookmarked_verses.includes(props.verseKey)){
                bookmarked_verses.push(props.verseKey)
                localStorage.setItem('bookmarked_verses', JSON.stringify(bookmarked_verses))
            }
        }else{
            localStorage.setItem('bookmarked_verses', JSON.stringify([props.verseKey]))
        }
        setIsBookmared(true)
    }

    /**
     * bookmark verse
    */
    function removeBookmark(){
        let bookmarked_verses = localStorage.getItem('bookmarked_verses')
        if(bookmarked_verses){
            bookmarked_verses = JSON.parse(bookmarked_verses)
            bookmarked_verses = bookmarked_verses.filter(bv => bv !== props.verseKey)
            localStorage.setItem('bookmarked_verses', JSON.stringify(bookmarked_verses))
        }
        setIsBookmared(false)
    }

    /**
     * render bookmark button
    */
    function renderBookmarkButton(){
        return isBookmarked ? (
            <button onClick={() => removeBookmark()} className="px-2 py-1 rounded-md hover:opacity-50 font-bold">
                <svg
                  className="h-8 w-8"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                </svg>
            </button>
        ) : 
        (
            <button title={t('Save as a bookmark')} onClick={() => bookmarkVerse()} className="px-2 py-1 rounded-md hover:opacity-50 font-bold">
                <svg
                  className="h-8 w-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                </svg>
            </button>
        )
    }

    /**
     * render close button
    */
    function renderCloseButton(){
        return (
            <button title={t('Close')} onClick={() => props.hideModal(true)} className="hidden md:block px-2 py-1 rounded-md hover:opacity-50 text-red-600 fixed top-5 right-5 font-bold">
                <svg
                  className="h-10 w-10"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <line x1={18} y1={6} x2={6} y2={18} /> <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
            </button>
        )
    }
    /**
     * render tab buttons
    */
    function renderTabNavigation(){
        return (
            <>
              <div 
               onClick={() => props.hideModal(true)}
               className={`md:hidden cursor-pointer bg-red-100 dark:bg-red-700 dark:border-neutral-700 hover:opacity-80 border py-2 md:p-2`}>
                {t('Close')}
               </div>

               <div 
               onClick={() => setActiveTab('recitation')} 
               className={`cursor-pointer bg-neutral-100 dark:bg-neutral-700 dark:border-neutral-700 hover:opacity-80 border py-2 md:p-2 ${activeTab === 'recitation' ? 'bg-neutral-200 dark:bg-neutral-800' : ''}`}>
                {t('Listen/Recitations')}
               </div>
   
               <div 
               onClick={() => setActiveTab('tafsirs')} 
               className={`cursor-pointer bg-neutral-100 dark:bg-neutral-700 dark:border-neutral-700 hover:opacity-80 border py-2 md:p-2 ${activeTab === 'tafsirs' ? 'bg-neutral-200 dark:bg-neutral-800' : ''}`}>
                {t('Tafsirs(Exegesis)')}
               </div>
   
               <div 
               onClick={() => setActiveTab('translations')} 
               className={`cursor-pointer bg-neutral-100 dark:bg-neutral-700 dark:border-neutral-700 hover:opacity-80 border py-2 md:p-2 ${activeTab === 'translations' ? 'bg-neutral-200 dark:bg-neutral-800' : ''}`}>
                {t('Translations')}
               </div>
            </>
        )
    }

    /**
     * render verse text uthmani container
    */
    function renderVerseText(){
        return (
            <p className="flex-1 text-3xl leading-loose verse">{props.verseText}</p>
        )
    }

    return (
        <div className="fixed top-0 right-0 w-screen h-screen flex backdrop-blur-md dark:bg-white/10 bg-black/10 z-20 overflow-y-scroll md:py-20">
            <div className="bg-neutral-50 dark:bg-neutral-800 w-full md:w-1/3 min-h-full md:min-h-auto md:m-auto relative rounded-md">
                <div className="grid grid-cols-4 md:grid-cols-3 text-center">
                    {renderTabNavigation()}
                </div>

                <div className="py-4 px-2 bg-neutral-100 dark:bg-neutral-800">
                    <div className="flex border-b dark:border-neutral-600 pt-16 pb-8 my-4 relative">
                       {renderVerseText()}
                       <div className={`absolute top-0 ${i18n.language === 'ar' ? 'left-0' : 'right-0'}`}>{renderBookmarkButton()}</div>
                    </div>
                    
                    {renderTabs()}
                </div>
                
                {renderCloseButton()}
            </div>
        </div>
    )
}