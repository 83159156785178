import React, { useState , useEffect} from "react";
import ChapterItem from "../Components/ChapterItem";
import Banner from "../Components/Banner";
import { VersePlaceholder } from "../Components/Placeholders/VersePlaceholder";
import BookmarksContainer from "../Components/Bookmarks/BookmarksContainer";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function IndexPage(){
    const [chapters ,setChapters] = useState()
    const [filteredChapters ,setFilteredChapters] = useState()
    const [orderStyle, setOrderStyle] = useState('desc')
    const [isLoading, setIsLoading] = useState(true)
    const {t ,i18n} = useTranslation()

    useEffect(() => {
      loadChapters()
    },[])

    /**
     * load chapters from local storage
    */
    function loadChaptersFromLocalStorage(){
        let chapters = localStorage.getItem('chapters_list')
        chapters = JSON.parse(chapters)
        setChapters(chapters)
        setFilteredChapters(chapters)
        setIsLoading(false)
    }

    /**
     * load chapters from API
    */
    function loadChaptersFromAPI(){
        setIsLoading(true)

        fetch(`https://api.quran.com/api/v4/chapters`)
        .then(response => response.json())
        .then(json => {
            setChapters(json.chapters)
            setFilteredChapters(json.chapters)
            setIsLoading(false)
            localStorage.setItem('chapters_list', JSON.stringify(json.chapters))
        })
    }

    /**
     * load chapters (surah's from api)
    */
    function loadChapters(){
        localStorage.getItem('chapters_list')
        ? loadChaptersFromLocalStorage()
        : loadChaptersFromAPI()
    }

    /**
     * filter chapters based on a 
     * given keyword
     */
    function filterChapters(query){
        if(!! query){
            let filtered = chapters.filter(chapter => {
                return (
                    chapter.name_arabic.includes(query) 
                    || chapter.name_complex.includes(query) 
                    || chapter.name_simple.includes(query)
                )
            })

            setFilteredChapters(filtered)
        }else{
            setFilteredChapters(chapters)
        }
    }

    /**
     * reorder chapters
     */
    function reOrderChapters(style){
        let sorted = [...chapters].sort((a,b) => (
            (style === 'asc') ? b.id - a.id : a.id - b.id
        ))
        
        setFilteredChapters(sorted)
        setChapters(sorted)
        setOrderStyle(style)
    }

    /**
     * render chapters or placeholders
    */
    function renderChapters(){
        return isLoading ? (
            <>
              <VersePlaceholder />
              <VersePlaceholder />
              <VersePlaceholder />
              <VersePlaceholder />
            </>
        ) : (
            filteredChapters?.map(chapter => <ChapterItem key={chapter.id} chapter={chapter} />)
        )
    }

    /**
     * render filter
    */
    function renderFilter(){
        return (
            <div className="flex my-2">
                <input onInput={(e) => filterChapters(e.target.value)} type="text" 
                className="p-2 border dark:bg-neutral-700 dark:border-none rounded focus:outline-none focus:border-2" 
                placeholder={t('Search for chapter')}/>
                <div className="flex-1"></div>
                <select defaultValue={orderStyle} onChange={(e) => reOrderChapters(e.target.value)} className="dark:bg-neutral-700 dark:border-none mr-auto text-sm border rounded">
                    <option value="asc">{t('Ascending')}</option>
                    <option value="desc">{t('Descending')}</option>
                </select>
            </div>
        )
    }

    return (
        <div className="dark:bg-neutral-900 bg-neutral-100">
            <Helmet>
                <title>{t('App name')}</title>
            </Helmet>
            <Banner></Banner>

            <div className="container py-6 mx-auto px-5 md:px-0 ">
                <h1 className="text-3xl font-bold my-4 border-b dark:border-neutral-700 py-4">{t('Bookmarks')}</h1>
                <div className="grid md:grid-cols-3 gap-4">
                    <BookmarksContainer chapters={chapters?.map(chapter => (
                   i18n.language === 'ar' ? ('سورة ' + chapter.name_arabic) : chapter.name_complex
                   ))}></BookmarksContainer>
                </div>
            </div>

            
  
            <div className="container py-24 mx-auto px-5 md:px-0 ">
              <h1 className="text-3xl font-bold my-4 border-b dark:border-neutral-700 py-4">{t('Chapters')}</h1>
              {renderFilter()}
              <div className="grid md:grid-cols-3 gap-8">
                  {renderChapters()}
              </div>
            </div>
        </div>
    )
}