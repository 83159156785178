import React from "react";
const backgroundImage = require('../../verse-symbol.png')

export function VerseSymbol(props){
    return (
        <div className="inline mx-2 relative text-center text-xl p-4 text-neutral-900" 
        style={{
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("${backgroundImage}")`}}>
            {props.number}
        </div>
    )
}