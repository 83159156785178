import React, { useState , useEffect} from "react";
import AudioPlaceholder from "./Placeholders/AudioPlaceholder";
import { useTranslation } from "react-i18next";

export default function RecitationAudio(props){
    const [recitators, setRecitators] = useState([])
    const {i18n} = useTranslation()

    useEffect(() => {
        loadRecitators()
    },[])

    /**
     * render the audio item
     */
    function renderAudioElement(){
        return props.url ? (
            <div className="flex-1">
                <audio className="w-full my-4" controls>
                  <source src={props.url} type="audio/mpeg" />
                   Your browser does not support the audio element.
                </audio>
            </div>
        ) : (
            <AudioPlaceholder />
        )
    }

    /**
     * render recitators selector
     */
    function renderRecitatorsList(){
        return props.url ? (
            <div className="mx-auto md:mx-5 text-center">
                <select value={props.recitatorId} onChange={(e) => props.changeRecitation(e)} className="cursor-pointer border dark:border-neutral-700 dark:bg-neutral-700  p-2 rounded-md">
                  {recitators?.map(recitator => (
                    <option key={recitator.id} value={recitator.id}>
                        {i18n.language !== 'ar' ? recitator.reciter_name : recitator.translated_name.name}
                    </option>
                  ))}
                </select>
            </div>
        ) : <></>;
    }

    /**
     * load recitators from API
    */
    function loadRecitatorsFromAPI(){
        fetch('https://api.quran.com/api/v4/resources/recitations?language=ar')
        .then(response => (response.json()))
        .then(json => {
            setRecitators(json.recitations)
            localStorage.setItem('recitators_list' ,JSON.stringify(json.recitations))
        })  
    }

    /**
     * load recitators from localStorage
    */
    function loadRecitatorsFromLocalStorage(){
        let recitators = localStorage.getItem('recitators_list')
        recitators = JSON.parse(recitators)
        setRecitators(recitators)
    }

    /**
     * render recitators list
    */
    function loadRecitators(){
        localStorage.getItem('recitators_list')
        ? loadRecitatorsFromLocalStorage()
        : loadRecitatorsFromAPI()
    }

    return (
        <div className="my-2 px-2 md:flex gap-2 items-center w-full">
          {renderAudioElement()}
          {renderRecitatorsList()}
        </div>
    )
}