import { createBrowserRouter, } from "react-router-dom";
import IndexPage from '../Pages/IndexPage';
import SurahPage from "../Pages/SurahPage";
import MainLayout from "../Layouts/Main";

export default createBrowserRouter([
    {
      path: "/",
      element: <MainLayout><IndexPage /></MainLayout> ,
    },
    {
      path: "/:chapterId",
      element: <MainLayout><SurahPage /></MainLayout>,
    },
])