import React, { useEffect } from "react";
import Footer from './Partials/Footer'
import Header from './Partials/Header'
import { useTranslation } from "react-i18next";

export default function MainLayout(props){
    const [t] = useTranslation()
    useEffect(() => {
        if(localStorage.getItem('mode')){
            document.documentElement.classList.add(localStorage.getItem('mode'))
        }
    })

    return (
        <>
           <div className="flex flex-col h-screen dark:bg-neutral-900 dark:text-neutral-50" dir={t('dir')}>
               <Header />
               <div className="flex-1 dark:bg-neutral-900">
                  {props.children}
               </div>
               <Footer />
           </div>
        </>
    )
}